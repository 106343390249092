import { Accordion, AccordionSummary } from "../../molecules/accordion/accordionHelpers";
import { LABELS } from "./constants";
import Logout from "../logout";
import { useNavigate } from "react-router-dom";
import { MY_ACCOUNT_EDIT_PROFILE_PAGE_ROUTE_PATH, MY_ACCOUNT_MY_ADDRESSES_LIST_PAGE_ROUTE_PATH, MY_QUOTES_PAGE_ROUTE_PATH, NOTIFICATION_LISTING_ROUTE_PATH, ORDERS_ROUTE_PATH, WISHLIST_ROUTE_PATH } from "../../../routes/routes-constant";
import { ReactComponent as EditProfileIcon } from './../../../globals/svg/edit-profile.svg'
import { ReactComponent as RightArrowIcon } from './../../../globals/svg/right_arrow.svg'
import * as S from "./styles";


const MyAccount = () => {
    const navigate = useNavigate();

    return (<S.Content>
        <div className="profile">
            <EditProfileIcon onClick={() => { navigate(MY_ACCOUNT_EDIT_PROFILE_PAGE_ROUTE_PATH) }} />
        </div>
        <Accordion className="accordion">
            <AccordionSummary onClick={() => { navigate(NOTIFICATION_LISTING_ROUTE_PATH) }} expandIcon={<RightArrowIcon />}>
                <div className="accordion-text">{LABELS.Notifications}</div>
            </AccordionSummary >
        </Accordion>
        <div className="accordion">
            <Accordion >
                <AccordionSummary onClick={() => { navigate(ORDERS_ROUTE_PATH) }} expandIcon={<RightArrowIcon />}>
                    <div className="accordion-text">{LABELS.Orders}</div>
                </AccordionSummary >
            </Accordion>
            <Accordion >
                <AccordionSummary onClick={() => { navigate(WISHLIST_ROUTE_PATH) }} expandIcon={<RightArrowIcon />}>
                    <div className="accordion-text">{LABELS.Wishlist}</div>
                </AccordionSummary>
            </Accordion>
            <Accordion >
                <AccordionSummary onClick={() => { navigate(MY_QUOTES_PAGE_ROUTE_PATH) }} expandIcon={<RightArrowIcon />}>
                    <div className="accordion-text">{LABELS.Quote}</div>
                </AccordionSummary>
            </Accordion>
            <Accordion >
                <AccordionSummary onClick={() => { navigate(MY_ACCOUNT_MY_ADDRESSES_LIST_PAGE_ROUTE_PATH) }} expandIcon={<RightArrowIcon />}>
                    <div className="accordion-text">{LABELS.Addresses}</div>
                </AccordionSummary>
            </Accordion>
        </div>
        <Accordion className="accordion">
            <AccordionSummary onClick={() => { window.open(process.env.REACT_APP_PDF_URL, "_blank"); }} expandIcon={<RightArrowIcon />}>
                <div className="accordion-text">{LABELS.Catalog}</div>
            </AccordionSummary >
        </Accordion>
        <Logout />
    </S.Content>
    )
}
export default MyAccount; 