import { useState } from "react";
import { getStaticConstantValue, formatPriceAndCurrency } from "../../../../../helpers/utlis";
import { Accordion, AccordionDetails, AccordionSummary } from "../../../../molecules/accordion/accordionHelpers";
import { OrderItem } from "../types";

const ItemList = ({ items, orderId, returnAll, returnItemIds, setReturnAll, setReturnItemIds }: ({
    items: OrderItem[], orderId: string, returnItemIds: string[], setReturnItemIds: React.Dispatch<React.SetStateAction<string[]>>,
    returnAll: ({ orderId: string, selectAll: boolean }), setReturnAll: React.Dispatch<React.SetStateAction<({ orderId: string, selectAll: boolean })>>
}
)) => {
    const [expanded, setExpanded] = useState<string>('');
    const returnableItemsCount = (items?.filter(item => item?.extension_attributes?.is_available_for_return) ?? []).length
    return <>
        {items?.map((item: OrderItem, index) => {
            const { sku, qty_ordered, name, base_row_total_incl_tax: rowTotal, extension_attributes, item_id = '' } = item;
            let { image, is_available_for_return } = extension_attributes ?? {}
            const productImageUrl = image ? ((process.env.REACT_APP_PDP_IMAGES_BASE_URL ?? "") + image) : (getStaticConstantValue("material_placeholder") ?? '')
            const isChecked = ((orderId === returnAll?.orderId) && (returnItemIds.includes(item_id) || returnAll?.selectAll))
            return (
            <div>
            <div className={(item?.child_item_array?.length>0 && item?.product_type=="bundle") ?"item-box-check-box":"item-box-check"} key={item_id}>
                <div className="item-box">
                    <img src={productImageUrl} alt={`item-${index}`} />
                    <div className='item-detail'>
                        <span>{name}</span>
                        <p>{formatPriceAndCurrency(rowTotal)}</p>
                        <div className='other'>
                            <div className="info"><span>SKU</span>:<p>{sku}</p></div>
                            <div className="info"><span>Qty</span>:<p>{qty_ordered}</p></div>
                        </div>
                    </div>
                </div>
                {is_available_for_return && <div key={'select-items' + item_id} className="checkbox-wrapper">
                    <input
                        id={'select-items'}
                        type="checkbox"
                        className="custom-checkbox"
                        name={'select-items'}
                        checked={isChecked}
                        onChange={() => {
                            if (returnItemIds.includes(item_id)) {
                                if (returnAll.orderId !== orderId) setReturnItemIds([item_id])
                                else setReturnItemIds(pre => pre.filter(val => val !== item_id))
                                setReturnAll(({ orderId, selectAll: false }))
                            }
                            else {
                                if (returnAll.orderId !== orderId) { setReturnItemIds([item_id]); setReturnAll({ orderId, selectAll: items.length === 1 }) }
                                else { setReturnItemIds(pre => [...pre, item_id]); setReturnAll(({ orderId, selectAll: ((returnableItemsCount === (returnItemIds.length + 1)) && (!returnAll?.selectAll)) })) }
                            }
                        }
                        }
                    />
                </div>}
            </div>
            {(item?.child_item_array?.length>0 && item?.product_type=="bundle") && <div className="acordian-div">
             <Accordion
             onChange={() => { setExpanded(expanded => expanded === String(item_id) ? '' : String(item_id)) }}
             expanded={expanded === String(item_id)}
             key={String(item_id)}
            >
             <AccordionSummary>
                 <div className="text sub-data-heading">{`Assembly Items`}</div>
             </AccordionSummary>
             <AccordionDetails >
             <ItemList items={item?.child_item_array} orderId={String(orderId)} returnAll={returnAll} setReturnAll={setReturnAll} returnItemIds={returnItemIds} setReturnItemIds={setReturnItemIds} />
             </AccordionDetails>
            </Accordion>
            </div>}
         </div>
            )
        })}
    </>
}

export default ItemList; 