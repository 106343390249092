import React, { useEffect, useState } from "react";
import apiList, { ADD_NEW_SHIPPING_ADDRESS, ALERT_STATUS, BILLING_ADDRESS_HEADING, DELIVERY_OPTION_HEADING, GIFT_CART_LABEL, IS_BILLING_ADDRESS_SAME_AS_SHIPPING, IS_THAT_A_GIFT, SAVE_BUTTON_LABEL, SELECT_ANOTHER_ADDRESS, SELECT_ANOTHER_BILLING_ADDRESS, SHIPPING_ADDRESS_HEADING, SOMETHING_WENT_WRONG, USER_TYPE } from "../../../../../lib/constant";
import { Accordion, AccordionDetails, AccordionSummary, PlusAccordionSummary } from "../../../../molecules/accordion/accordionHelpers";
import { useNotification } from "../../../../../hooks/useNotification";
import { deliveryMethodPageProps, deliveryMethodProps } from "../../types/types";
import { useNavigate } from "react-router-dom";
import { formatPriceAndCurrency } from "../../../../../helpers/utlis";
import { setLocalStorage } from "../../../../../helpers/localStorageUtil";
import { doActionGet } from "../../../../../helpers/httpRequest";

const formatDate = (inputDate: string): string => {
    const date = new Date(inputDate);
  
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', month: 'long' };
    const formattedDate = date.toLocaleDateString('en-GB', options);
  
    const day = date.getDate();
    const ordinalSuffix = (n: number): string => {
      const s = ["th", "st", "nd", "rd"];
      const v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    };
  
    const dayWithSuffix = ordinalSuffix(day);
    const [dayName, month] = formattedDate.split(" "); // Split formatted string to get parts
  
    return `${month} ${dayWithSuffix} of ${dayName}`;
  };
  

const ShipmentMethod: React.FC <deliveryMethodPageProps>= ({carrierTitle,shipmentMethodList,onChange,changeGiftHandler,isGiftOrder,onSlotChange,shipmentSelectedSlot}) => {
    const [isAPICallRunning,setIsAPICallRunning] =useState(false);
    const [isGiftCart,setIsGiftCart] =useState<Boolean>();
    const { showAlert } = useNotification();
    const [availableShipmentMethods, setAvailableShipmentMethods] =useState<deliveryMethodProps[]>([]);
    const [selectedCarrierCode , setSelectedCarrierCode] =useState<string>("");
    const [selectedMethodCode,setSelectedMethodCode] =useState<string>("");
    const navigate = useNavigate();
    const [expressDeliverySlots,setExpressDeliverySlots]= useState<any>([]); 
    // const expressDeliverySlots = [
    //     {
    //         "date": "2024-11-19",
    //         "slots": [
    //             {
    //                 "start_time": "3:37 pm",
    //                 "end_time": "3:37 pm"
    //             },
    //             {
    //                 "start_time": "8:55 pm",
    //                 "end_time": "11:55 pm"
    //             }
    //         ]
    //     },
    //     {
    //         "date": "2024-11-20",
    //         "slots": [
    //             {
    //                 "start_time": "6:37 pm",
    //                 "end_time": "8:37 pm"
    //             }
    //         ]
    //     }
    // ];
    const [selectedSlot,setSelectedSlot] = useState<number>();
    const [selectedMainDate,setSelectedMainDate] = useState("");
    const [selectedStartTime,setSelectedStartTime] = useState("");
    const [selectedEndTime,setSelectedEndTime] = useState("");

    const filterSlots = (data: any) => {
      const now = new Date();
    
      return data?.map((day:any) => {
          const filteredSlots = day?.slots?.filter((slot:any) => {
            const slotTime = new Date(`${day?.date} ${slot?.start_time}`);
            return slotTime >= now; // Keep slots starting after current time
          });
    
          return filteredSlots.length > 0
            ? { ...day, slots: filteredSlots }
            : null; // Keep only days with remaining slots
        })
        .filter((day:any) => day !== null); // Remove null entries
    };
    

    useEffect(()=>{
        if(shipmentSelectedSlot){
          const dateEndIndex = shipmentSelectedSlot?.indexOf(" ");
          const date = shipmentSelectedSlot?.slice(0, dateEndIndex); // Extract the date
          const timeRange = shipmentSelectedSlot?.slice(dateEndIndex + 1); // Extract the time range
            setSelectedMainDate(date);
            const [startTime, endTime] = timeRange?.split("-");
            setSelectedStartTime(startTime?.trim());
            setSelectedEndTime(endTime?.trim());
            onSlotChange(date,timeRange);
        }
    },[shipmentSelectedSlot])

    const  radioButtonChangeHandler=(item:deliveryMethodProps)=>{
        setSelectedCarrierCode(item.carrier_code);
        setLocalStorage("deliveryCarrierCode",item.carrier_code);
        setSelectedMethodCode(item.method_code);
        onChange(item);
    }

    useEffect(()=>{
        const fetchData = async()=> {
        await fetchSlotData();
        }
        fetchData();
      },[])
    const fetchSlotData=async()=>{
        setIsAPICallRunning(true);
        doActionGet({
          url: apiList.getSlotData,
          userType:USER_TYPE.Customer
        })?.then((resp: any) => {
            setExpressDeliverySlots(filterSlots(resp?.data));
        }
        ).catch((error: any) => {
          console.log("error msg", error);
          showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
        }).finally(()=>{
          setIsAPICallRunning(false);
        })
    }


    useEffect(()=>{
        setIsGiftCart(isGiftOrder);
    },[isGiftOrder])
    useEffect(()=>{
        setAvailableShipmentMethods(shipmentMethodList);
    },[shipmentMethodList])

    const giftCartChangeHandler = () =>{
        changeGiftHandler(!isGiftCart);
        setIsGiftCart(isGiftCart => !isGiftCart);
    }
  
    const slotChangeHandler= (index:any,slotLabel:string)=>{
        setSelectedSlot(index);
        onSlotChange(selectedMainDate,slotLabel);
        setSelectedStartTime("");
        setSelectedEndTime("");
    }

    return (
        <div className="customer-delivery">
            <div className="address-heading">{DELIVERY_OPTION_HEADING}</div>
        <div className="page-delivery-sub-heading"></div>
        {availableShipmentMethods?.length>0 && 
            availableShipmentMethods.map((item:deliveryMethodProps, index: number)=>{
                return (
                  <React.Fragment key={item?.carrier_code}>
                <div className={item.carrier_code===selectedCarrierCode ?"shipment-section":""}>
                <div className="shipment-method-section">
                      <div className="shipment-method-title-section">
                        <div className="shipment-content">
                            <div className="delivery-method-item"> {item?.method_title}</div>
                            <div className="shipment-method-sub-title">{item?.extension_attributes?.delivery_message}</div>
                        </div>
                        <div className="shipment-method-amount">{item?.amount===0?"Free":formatPriceAndCurrency(item?.amount)}</div>
                        <div className="address-radio">
                        <input className="custom-radio" type="radio" id="address-radio-button" value={item?.carrier_code} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>radioButtonChangeHandler(item)} checked={item?.carrier_code===selectedCarrierCode||item?.carrier_title===carrierTitle}></input>
                      </div>
                      </div>
                </div>
                {item?.carrier_code ==="expressdelivery"  && (selectedCarrierCode==="expressdelivery"|| item?.carrier_title===carrierTitle )&&
                      expressDeliverySlots.map((slotData:any) => {
                        return (
                          <div className="split-section-box">
                            <div className="address-check-section delivery-slot-section">
                              <input 
                                className="custom-checkbox" 
                                type="checkbox" 
                                checked={slotData?.date === selectedMainDate}
                                onChange={() =>{setSelectedMainDate(slotData?.date);onSlotChange(slotData?.date);setSelectedSlot(100)}} 
                              />
                              <label className="split-text">{formatDate(slotData?.date)}</label>
                            </div>
                            <div className="split-sub-text slot-sub-label">
                              {slotData.slots.map((subSlot:any,index:any) => (
                                <div className={(slotData?.date === selectedMainDate && (selectedSlot===index|| (selectedStartTime===subSlot?.start_time && selectedEndTime === subSlot?.end_time)))?"slot-div selected-slot":"slot-div" } onClick={()=>(slotData?.date === selectedMainDate)?slotChangeHandler(index,subSlot.start_time+"-"+subSlot?.end_time):{}}>{subSlot.start_time + " to " + subSlot?.end_time}</div>
                              ))}
                            </div>
                          </div>
                        );
                      })
                    }
                 <hr className="item-line"/>
                 </div>
                 </React.Fragment>
                )
            })    
        }
         <div className="line-item gift-line-section"> {IS_THAT_A_GIFT}</div>
                         <div className="gift-section">
          <input className="gift-checkbox" type="checkbox" checked={Boolean(isGiftCart)}
          onChange={giftCartChangeHandler}/>
          <label className="consent-text">{GIFT_CART_LABEL}</label>
          </div>
    </div>
  );
};

export default ShipmentMethod;