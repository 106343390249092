import { Autocomplete } from "@mui/material";
import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p:any) => {
//     return css`
//     .customer-checkout{
//         margin-left:4%;
//         margin-right:4%;
//     }
//     .checkout-header{
//         display:flex;
//         height:56px;
//     }
//     .headertext{
//            align-content: center;
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 500;
//     line-height: 110%;
//     margin-top: 32px;
//     margin-left: 30px;
//     margin-right: 30px;
//     margin-bottom: 16px;
//     }
//     .address-heading{
//         font-Weight:600;
//         font-size:14px;
//         line-height:21px;
//     }
//     .checkout-new-billing-address-section{
//       display:flex;
//       justify-content:center;
//     }
//     .checkout-address-section{
//         display: grid;
//         grid-template-columns: 1fr 1fr;
//         margin-bottom:18px;
      
//     }
//     .checkout-billing-address-section{
//         display: grid;
//         grid-template-columns: 1fr 1fr;
//         margin-bottom:18px;
//         margin-top:35px;
//     }
//     .add-new-address{
//         font-Weight:400;
//         font-size:10px;
//         line-height:15px;
//         color:var(--sku-font-color);
//         width:96px;
//         height:16px;
//         border-radius:12px;
//         border: 1px solid var(--sku-font-color);
//         display:flex;
//         justify-content:center;
//     }
//     .add-new-address-list{
//       font-Weight:400;
//       font-size:10px;
//       line-height:15px;
//       color:var(--sku-font-color);
//       width:68px;
//       height:16px;
//       border-radius:12px;
//       border: 1px solid var(--sku-font-color);
//       display:flex;
//       justify-content:center;
//   }
//     .new-address-section{
//         display:flex;
//         justify-content:end;
//     }
//     .address-row{
//         display:flex;
//     }
//     .first-address-row{
//         display:flex;
//         column-gap:4px;
//         font-weight:500;
//         font-size:16px;
//         line-height:24px;
//     }
//     .first-address-card-row{
//       display:flex;
//         column-gap:4px;
//         font-weight:500;
//         font-size:16px;
//         line-height:24px;
//         justify-content:space-between;
//     }
//     .address-row{
//         font-weight:500;
//         font-size:14px;
//         line-height:21px;
//         color:var(--sku-font-color);
//     }
//     .item-line{
//         border-bottom-color: var(--line-color); 
//         margin-bottom:14px;
//     }
//    .address-content-section{
//         display:flex;
//         justify-content: space-between;  
//    }
//    
//    .custom-radio:checked{
//     accent-color:var(--sand-button-color);
//     border:1px solid ;
//    }

//    .checkout-continue-button{
//     margin-top:69px;
//    }

//    .page-sub-heading{
//     font-weight:700;
//     font-size:16px;
//     line-height:24px;
//     margin-top:16px;
//     margin-bottom:16px;
//    }
   
//    .shipment-content {
//     max-width: 85%;
//    }

//    .page-delivery-sub-heading{
//     margin-top:16px;
//     margin-bottom:28px;
//    }
//    .shipment-method-section{
//     display:flex;
//     column-gap:12px;
//    }
//    .shipment-method-title-section{
//     display:flex;
//     width:100%;
//     justify-content:space-between;
//    }
//    .shipment-method-title{
//     font-weight:500;
//     font-size:16px;
//     line-height:24px;
//    }
//    .shipment-method-sub-title{
//     font-weight:500;
//     font-size:14px;
//     line-height:21px;
//     color:var(--sku-font-color);
//    }
//    .shipment-method-amount{
//     font-weight:700;
//     font-size:15px;
//     line-height:20px;
//    }
//    .shipment-method-section{
//     padding-left:9px;
//     padding-right:9px;
//     padding-top:12px;
//        margin-bottom: 20px;
//     margin-top: 20px;
//    }
//    .shipment-section{
//     background-color:var( --light-grey);
//    }
//    .first-content-row{
//     font-weight:500;
//     font-size:16px;
//     line-height:24px;
//    }
//    .head-content-row{
//     font-weight:600;
//     font-size:16px;
//     line-height:24px;
//     margin-bottom :5px;
//    }
//    .second-content-row{
//     font-weight:500;
//     font-size:14px;
//     line-height:21px;
//     color:var(--sku-font-color);
//    }
//    .card-section{
//     margin-top:18px;
//     background-color: #ffffff; /* White background */
//     border-radius:4px;
//     box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
//     padding-left:20px;
//     padding-top:15px;
//     padding-bottom:15px;
//    }
//    .card-payment-section{
//     margin-top:18px;
//     background-color: #ffffff; /* White background */
//     border-radius:4px;
//     box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
//     padding-left:20px;
//     padding-top:15px;
//     padding-bottom:15px;
   
//     padding-right:20px;
//    }
//    .summary-line{
//     border-bottom: 2px solid var(--apply-button-color);
//     margin-top:13px;
//    }
//    .cost-info {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//    }
//    .cost-info div {
//     color: var(--DARK-BLACK);
//     font-family: Poppins;
//     font-size: 12px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 15px;
//     width: 45%;
//   }
//   span {
//     width: 10%;
//     text-align: end;
//   }
//   .cost-info p {
//     color: var(--grey-text);
//     text-align: right;
//     font-family: Poppins;
//     font-size: 13px;
//     font-style: normal;
//     font-weight: 600;
//     line-height: 16px;
//     width: 45%;
//   }
//   .total-amount {
//     border-top: 1px solid #c6c5c5;
//     margin: 30px 0;
//   }
//   .total-amount p {
//     color: var(--grey-text);
//     text-align: right;
//     font-family: Poppins;
//     font-size: 15px;
//     font-style: normal;
//     font-weight: 700;
//     line-height: 20px;
//     width: 45%;
//   }
//   .cost-total {
//     text-align: left !important;
//   }
//   button {
//     color: var(--white);
//     text-align: center;
//     font-family: Montserrat;
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 500;
//     line-height: 25px;
//     background: rgb(111, 104, 85);
//     border-radius: 8px;
//     border: none;
//     padding: 5px;
//     width: 50%;
//     align-self: center;
//   }
//   .success-main-heading{
//     font-weight:700;
//     font-size:22px;
//     line-height:28px;
//     color:var(--sand-button-color);
//     text-align:center;
//     margin-bottom:13px;
//   }
//   .success-sub-heading{
//     font-weight:500;
//     font-size:15px;
//     line-height:20px;
//     color:var(--sand-button-color);
//     text-align:center;
//   }
//   .order-success-logo{
//     display:flex;
//     margin-left:auto;
//     margin-right:auto;
//     margin-bottom:19px;
//   }
//   .order-id{
//     font-weight:800;
//     font-size:17px;
//     text-decoration: underline;

//   }
//   .order-success{
//     margin-top:100px;
//   }
//   .failure-sub-heading{
//     font-weight:500;
//     font-size:15px;
//     line-height:20px;
//     color:var(--sand-button-color);
//     text-align:center;
//   }
//   .code-title{
//     font-weight:600;
//     font-size:11px;
//     line-height:13px;
//   }
//   .payment-method-section{
//     width:90px;
//     height:60px;
//     border:1px solid var(--sku-font-color);
//     display:flex;
//     text-align:center;
//     justify-content:center;
//     flex-direction: column;
//     padding:5px 8px;
//     border-radius:10px;
//   }
//   .payment-selcted-method-section{
//     width:90px;
//     height:60px;
//     display:flex;
//     text-align:center;
//     justify-content:center;
//     flex-direction: column;
//     padding:5px 8px;
//     border-radius:10px;
//     background-color:var(--sand-button-color);
//     color:white;
//   }
//   .payment-method-list-section{
//     display:flex;
//     justify-content: space-around;
//     margin-bottom :34px;
//   }
//   .checkout-card-section{
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//   }
//   .card-heading{
//     font-weight:700;
//     font-size:18px;
//     line-height:27px;
//   }
// .card-element{
//   height:41px;
// }
// .card-error{
//   color:red;
//   font-size:12px;
//   margin-bottom:10px;
// }
// .card-heading{
//   margin-bottom:10px;
//   font-size:16px;
// }
// .gift-section{
//   margin-bottom:24px;
//   display:flex;
//   flex-direction:row;
//   column-gap:12px;
// }
// .address-check-section{
//   margin-top:12px;
//   display:flex;
//   flex-direction:row;
//   column-gap:12px;
// }
// .selected-address{
//   background-color:var(--sand-button-color);
//   color:white;
// }
// .gift-checkbox{
//   width:18px;
//   height:18px;
//   border-box:2px;
// accent-color:var(--input-button-border-color);
// }
// .consent-text{
//   font-weight:500;
//   font-size:12px;
//   line-height:18px;
// }
// .checkout-header{
//   display:flex;
//   justify-content: space-between;
// }
// .card-number-row{
//   display:flex;
//   justify-content:space-between;
// }
// .card-number-label{
//   font-weight:500;
//   font-size:12px;
//   line-height:18px;
//   color:var(--sku-font-color);
// }
// .card-number{
//   font-weight:500;
//   font-size:14px;
//   line-height:21px;
// }
// .card-exp-row{
//   display:grid;
//   grid-template-columns:1fr 1fr;
//   column-gap:18px;
// }
// .input-box{
//   width: 97%;
//   padding-left: 11px;
//   color: var(--input-field-text-color);
//   line-height: 21px;
//   margin-bottom: 8px;
//   border: 1px solid rgb(218, 218, 218);
//   border-radius: 3px;
//   font-size: 14px;
//   padding-top: 10px;
//   padding-bottom: 9px;
 
// }
// .card-holder-heading{
//   font-size: 14px;
//   margin-bottom: 3px;
// }

// .input-box:focus{
//   outline:none;
// }
// .transaction-box{
//   margin-bottom:10px;
// }
// .consent-section{
//   margin-bottom:24px;
//   display:flex;
//   flex-direction:row;
//   column-gap:17px;
//   margin-top:20px;
// }
// .consent-checkbox{
//   width:20px;
//   height:20px;
// accent-color:var(--input-button-border-color);
// }
// .consent-text{
//   font-size:12px;
//   line-height:18px;
 
// }
// .moveToCartBtn button:disabled {
//   color:#B9B9B9;
//   background:#F6F6F6;
// }
// .payment-button{
//   margin-top:20px;
// }
//     `
    return css`
    .checkout-header{
        display:flex;
        height:56px;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
    .headertext{
      align-content: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      margin-top: 32px;
      margin-left: 30px;
      margin-right: 30px;
      margin-bottom: 16px;
      color:#6F6855;
    }
    .gift-section{
    display:flex;
    }
    .customer-delivery{
      background-color:white;
      padding: 16px 30px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      margin-bottom:16px;
    }
      .customer-payment-section{
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
       background-color: #fff;
        box-shadow: 0px 4px 18px 0px rgba(111, 104, 85, 0.1); 
         padding:16px 30px;
        position:relative;
       
      }
    .address-heading{
      color: var(--Sand, #6F6855);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
       margin-bottom: 16px;
    }
      .first-address-row{
        color: var(--Sand, #6F6855);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; 
        margin-bottom:10px;
      }
      .address-row{
      color: var(--Silver-Foil, var(--Soft-Sand, #B6B2A6));
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%
      }
      .custom-radio:checked{
        accent-color:var(--sand-button-color);
        border:1px solid ;
        height:20px;
        width:20px;
        }
         .custom-radio{
        accent-color:var(--sand-button-color);
        border:1px solid ;
        height:20px;
        width:20px;
        }
        .address-data{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom:20px;
        }
        .card-address-data{
        flex-direction: row;
        justify-content: space-between;
        margin-bottom:20px;
        }
        .line{
        border-bottom: 1px solid var(--Soft-Sand, #B6B2A6);
        
        }
        .address-name{
        font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 17.6px; 
          color:#6F6855;
        }
          
        .select-handler{
          color: var(--Sand, #6F6855);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 20.8px */
          letter-spacing: 0.48px;
          border-radius: 4px;
          border: 1px solid var(--Soft-Sand, #B6B2A6);
          background: var(--White, #FFF);
        }
          .address-list-section{
          padding:4px;
          }
          .delivery-method-item{
          margin-bottom:5px;
          color:black;
          font-weight:550;
          }
          .line-item{
          margin-bottom:5px;
          color:black;
          font-weight:550;
             padding-left: 10px;
    padding-top: 16px;
          }
          .line-item-selected{
              padding-left: 10px;
    padding-top: 16px;
          margin-bottom:5px;
          color:black;
          font-weight:550;
         background: var(--Chinese-White, #E3E2DE);
          }
          .accordian-summary{
          padding:0px;
          }
          .inner-accordian{
           padding:0px;
          }
          .input-box{
              width: 95%;
              margin-top: 13px;
              padding: 10px;
              flex-direction: column;
              border-radius: 4px;
              border: 1px solid var(--Soft-Sand, #B6B2A6);
              background: var(--White, #FFF);
              font-size: 14px;
          font-weight: 500;
          line-height: 18.2px;
          }
               .select-input-box{
              width: 101%;
              margin-top: 13px;
              padding: 10px;
              flex-direction: column;
              border-radius: 4px;
              border: 1px solid var(--Soft-Sand, #B6B2A6);
              background: var(--White, #FFF);
              color:#6F6855;
              appearance: auto;
                font-size: 14px;
          font-weight: 500;
          line-height: 18.2px;
              display: flex; /* Ensure flex container does not restrict width */
          }
              .select-input-box:focus{
  outline:none;
}
                .drop-down{
              width: 100% !important;
              overflow:auto !important;
              margin-top: 13px;
              padding: 10px;
              border-radius: 4px;
              border: 1px solid #B6B2A6;
              background: #FFF;
              position: static !important; /* Ensure default positioning */
                display: block !important; /* Ensure normal display */
                appearance: auto !important; 
          }
                #country option{
                width:100%;
                    min-block-size:50px;
                    max-block-size:100px;   
                    font-size: 14px;
          font-weight: 500;
          line-height: 18.2px;
          color:#6F6855;
              }
              #country selected{
              background-color:#6F6855;
               accent-color:##6F6855;
               font-weight: 500;
          line-height: 18.2px;
          color:#6F6855;
              }
          .save-button{
          display: flex;
          height: 50px;
          padding: 7px 52px;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          background: var(--Sand, #6F6855);
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.48px;
          color: var(--White, #FFF);
          text-align: center;
          border: 1px solid var(--Soft-Sand, #B6B2A6);
          }
          .button-section{
          display:flex;
          justify-content:center;
          margin-top:10px;
          margin-bottom: 10px;
          }
           .shipment-method-sub-title{
            font-weight:500;
            font-size:14px;
            line-height:21px;
            color:var(--sku-font-color);
          }
          .shipment-method-title-section{
            display: grid;
            grid-template-columns: 10fr 3fr 1fr;
            margin-bottom:32px;
            margin-top:32px;
          }
            .gift-checkbox{
            margin-right:14px;
            accent-color:var(--input-button-border-color);
            }
            .gift-line-section{
            margin-top:20px;
            margin-bottom:10px;
            }
            .continue-button{
            margin-top:30px;
            margin-bottom:40px;
            }
             .payment-method-list-section{
              display:flex;
              justify-content: space-around;
            
              column-gap:16px;

            }
            .payment-selcted-method-section{
            display: flex;
            padding: 4px 7px 8px 7px;
            flex-direction: column;
            align-items: center;
            gap: 1px;
            border-radius: 4px;
            border: 1px solid var(--Soft-Sand, #B6B2A6);
            }
            .payment-method-section{
            width:80.5px;
            display: flex;
            padding: 4px 7px 8px 7px;
            flex-direction: column;
            align-items: center;
            gap: 1px;
            border-radius: 4px;
            border: 1px solid var(--Soft-Sand, #B6B2A6);
            color: var(--Silver-Foil, var(--Soft-Sand, #B6B2A6));
            }
            .payment-method-section-selected{
             width:80.5px;
            display: flex;
            padding: 4px 7px 8px 7px;
            flex-direction: column;
            align-items: center;
            gap: 1px;
            border-radius: 4px;
            border: 1px solid var(--Soft-Sand, #B6B2A6);
            color:white;
            background-color:#6F6855;
            }
            .code-title{
            text-align: center;
            /* Links */
            font-family: Poppins;
            font-size: 9px;
            font-style: normal;
            font-weight: 500;
            line-height: 15px;
            }
            .address-list-data{
            padding-top:0;
            }
             .cost-info {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .cost-info div {
              color: var(--DARK-BLACK);
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 20.8px;
              width: 45%;
            }
          .cost-info p {
              color: #6F6855;
              text-align: right;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 17.6px;
              width: 45%;
            }
            .total-line{
            margin-top:6px;
            margin-bottom:24px;
            }
            .grand-total-line{
            margin-top:20px;
            margin-bottom:24px;
            }
          .cost-total {
            text-align: left !important;
              font-size: 16px !important;
          font-style: normal !important;
          font-weight: 600 !important;
          line-height: 24px !important;
          color:#1C1C1C !important;
          }
          .cost-total-p{
              font-size: 16px !important;
          font-style: normal !important;
          font-weight: 600 !important;
          line-height: 24px !important;
          color:#1C1C1C !important;
          }
          .first-row{
          margin-bottom:16px;
          }
            .checkout-card-section{
              display: grid;
              grid-template-columns: 1fr 1fr;
              margin-top:16px;
            }
              .card-element{
                height:41px;
              }
              .card-error{
                color:red;
                font-size:12px;
                margin-bottom:10px;
              }
              .card-heading{
                margin-bottom:10px;
                font-size:14px;
                font-weight:500;
                line-height:18.2px;
                color:#6F6855;
              }
                 .new-address-section{
                      display:flex;
                    justify-content:end;
              }
              .card-payment-section{
                margin-top:18px;
                background-color: #ffffff; /* White background */
                border-radius:4px;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                padding-left:20px;
                padding-top:15px;
                padding-bottom:15px;
              
                padding-right:20px;
              }
                 .first-address-card-row{
      display:flex;
        column-gap:4px;
        font-weight:500;
        font-size:16px;
        line-height:24px;
        justify-content:space-between;
    }
    .address-row{
        font-weight:500;
        font-size:14px;
        line-height:21px;
        color:var(--sku-font-color);
    }
    .item-line{
        border-bottom-color: var(--line-color); 
        margin-bottom:14px;
    }
          .custom-radio:checked{
    accent-color:var(--sand-button-color);
    border:1px solid ;
   }

   .checkout-continue-button{
    margin-top:69px;
   }
    .card-number-row{
  display:flex;
  justify-content:space-between;
}
.card-number-label{
  font-weight:500;
  font-size:14px;
  line-height:18.2px;
  color:#B6B2A6;
}
.card-number{
  font-weight:500;
  font-size:14px;
  line-height:18.2px;
  color:#6F6855;
}
.card-exp-row{
  display:grid;
  grid-template-columns:1fr 1fr;
  column-gap:18px;
}
      .add-new-address{
        font-Weight:500;
        font-size:10px;
        line-height:15px;
        color:#B6B2A6;
        width:96px;
        height:16px;
        border-radius:23px;
        border: 1px solid var(--sku-font-color);
        display:flex;
        justify-content:center;
        padding:4px, 8px, 4px, 8px;
    }
    .add-new-address-list{
      font-Weight:400;
      font-size:10px;
      line-height:15px;
      color:var(--sku-font-color);
      width:68px;
      height:16px;
      border-radius:12px;
      border: 1px solid var(--sku-font-color);
      display:flex;
      justify-content:center;
  }
      .card-holder-heading{
  font-size: 14px;
  margin-bottom: 3px;
}

.input-box:focus{
  outline:none;
}
  .second-content-row{
    font-weight:500;
    font-size:14px;
    line-height:21px;
    color:var(--sku-font-color);
    margin-bottom:10px;
   }
    .consent-section{
  margin-bottom:24px;
  display:flex;
  flex-direction:row;
  column-gap:12px;
  margin-top:20px;
      align-items: center;
}
.consent-checkbox{
  width:20px;
  height:20px;
accent-color:var(--input-button-border-color);
}
.consent-text{
  font-size:12px;
  line-height:18px;
 
}
    .cash-payment-heading{
    margin-top:32px;
    margin-bottom:16px;
     font-weight:500;
    font-size:16px;
    line-height:17.6px;
    color:#6F6855;
    text-align:center;
    }
    .info-title{
    font-weight:500;
    font-size:10px;
    line-height:15px;
    color:#B6B2A6;
    }
    .info-data-section{
    margin-bottom:15px;
    }
    .info-data{
    display:flex;
    justify-content:space-between;
    margin-top:8px;
    margin-bottom:16px;
    }
    .bank-info{
    padding-top:16px;
    margin-bottom:32px;
    }
    .info-heading{
    font-weight:500;
    font-size:16px;
    line-height:17.6px;
    color:#6F6855;
    }
    .copy-section{
    font-weight:500;
    font-size:10px;
    line-height:15px;
    color:#6F6855;
    }
    .copy-section {
  display: inline-block;
  position: relative;
}

.copy-section::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  border-bottom: 1px solid #000;
  }
  .transaction-box{
  font-weight:500;
    font-size:16px;
    line-height:17.6px;
    color:#6F6855;
  }
    .transaction-input-box{
    font-weight:500;
    font-size:10px;
    line-height:15px;
    color:#B6B2A6;
    }
    .place-net-order{
        margin-bottom: 60px;
    margin-top: 30px;
}
    .checkout-card-data{
    margin-top:32px;
    margin-bottom:32px;
    }
    .bank-input{
    margin-top:16px;
    }
      .success-main-heading{
    font-weight:700;
    font-size:22px;
    line-height:28px;
    color:var(--sand-button-color);
    text-align:center;
    margin-bottom:13px;
  }
  .success-sub-heading{
    font-weight:500;
    font-size:12px;
    line-height:20px;
    color:var(--sand-button-color);
    text-align:center;
  }
  .order-success-logo{
    display:flex;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:19px;
  }
  .order-id{
    font-weight:600;
    font-size:17px;
    text-decoration: underline;

  }
  .order-success{
    margin-top:100px;
        margin-left: 30px;
    margin-right: 30px;
  }
  .failure-sub-heading{
    font-weight:500;
    font-size:15px;
    line-height:20px;
    color:var(--sand-button-color);
    text-align:center;
  }
    .select-input-field{
    margin-top:13px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    }
    .save-button:disabled {
    background-color: var(--disabled-button-color);
    color: var(--disabled-text-color);
    cursor: not-allowed;
    box-shadow: none;
}
        }
.total-summary-section{
    margin:30px;
}
    .total-heading{
      margin-top:16px;
    }
    .address-row-section{
      display:grid;
   grid-template-columns:1fr 0.1fr 1fr;
    }
    .vertical-line{
    border-left: 2px solid #b6b2a6; /* Thickness and color of the line */
  height: 100px; /* Height of the line */
  margin: 10px;
    }
  .cost-grand-total{
      margin-block-start: 0px !important;

  }
       .phone-input-container {
    display: flex;
    align-items: center; 
}
      .phone-code-dropdown{
    width:35%;
    margin:13px 0px;
  }
  .phone-field{
  width:80%;
  }
  .tel-input-box{
    margin-top: 0px;
    width: 100%;
  }
    .tel-error-box{
    margin-top: 24px;
    width: 100%;
    }
    `
}}
`

export  const cardStyle = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "28px",
      "::placeholder": {
        color: "#32325d"
      }
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

export const StyledAutocomplete = styled(Autocomplete)`
  .MuiInputBase-root {
    font-size: 10px;
    width:100%;
      height: 40px;
      
      border: 0.5px solid #b6b2a6;
      background-color:#FFFFFF;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      padding-left:10px !important;
      outline:none;
       color: #1C1C1C;
   
      border-radius: 6px;
      border:none;
    
  }
  .MuiOutlinedInput-input {
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    color: #1c1c1c;
    
  }
   
`;