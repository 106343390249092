import React, { useEffect, useState } from "react";
import * as S from "../styles/styles";
import CheckoutHeader from "../checkout-header";
import CheckoutProgressBar from "../../../molecules/checkout-progress-bar";
import apiList, { ALERT_STATUS, BANK_TRANSFER_LABEL, CASH_ON_DELIVERY_CODE, CHECKOUT_FIRST_STEP_HEADING, CHECQUE_LABEL, CHEQUE_PURCHASE_ORDER_CODE, PAYMENT_METHOD, PAYMENT_METHOD_SUB_HEADING, PURCHASE_ORDER_CODE, SOMETHING_WENT_WRONG, STRIPE_PAYMENT_METHOD_CODE, USER_TYPE, progressBar } from "../../../../lib/constant";
import { useNotification } from "../../../../hooks/useNotification";
import { doActionGet } from "../../../../helpers/httpRequest";
import { Loading } from "../../../atoms/loading/Loading";
import { availablePaymentMethodType, paymentIconMappingType } from "../types/types";
import CustomerCardListing from "./card-listing";
import selectedCheckmo from '../../../../globals/svg/selectedCheckmo.svg';
import checkmo from '../../../../globals/svg/checkmo.svg'
import selectedstripe_payments from "../../../../globals/svg/selectedstripe_payments.svg";
import stripe_payments from "../../../../globals/svg/stripe_payments.svg";
import selectedPurchaseorder from "../../../../globals/svg/selectedPurchaseorder.svg";
import purchaseorder from "../../../../globals/svg/purchaseorder.svg";
import chequeIcon from "../../../../globals/svg/chequeIcon.svg";
import selectedChequeIcon from "../../../../globals/svg/selectedChequeIcon.svg";
import { useLocation } from "react-router-dom";
import { CHECKOUT_PAYMENT_CARD_FORM_PATH, CHECKOUT_PAYMENT_PATH } from "../../../../routes/routes-constant";
import CustomerPaymentForm from "./payment-form";
import NetBanking from "./net-banking";
import Cash from "./cash";
import { getPaymentMethodTitle, gtmDataConstructor } from "../helper";
import Cheque from "./cheque";


const CustomerCheckoutPayment: React.FC = () => {
    const [isAPICallRunning,setIsAPICallRunning] =useState(false);
    const { showAlert } = useNotification();
    const [availablePaymentMethod,setAvailablePaymentMethod] =useState<availablePaymentMethodType[]>([]);
    const [selectedPaymentMethod,setSelectedPaymentMethod] = useState<String>(STRIPE_PAYMENT_METHOD_CODE);
    const location =useLocation();

    //This will be revisit  once backend work be done
    const paymentMethodCodeAndIconMapping = [
      { key: CASH_ON_DELIVERY_CODE, value: checkmo},
      { key: STRIPE_PAYMENT_METHOD_CODE, value: stripe_payments },
      { key: PURCHASE_ORDER_CODE, value: purchaseorder },
      {key:CHEQUE_PURCHASE_ORDER_CODE, value:chequeIcon}
    ];

    const paymentMethodSelectedCodeAndIconMapping = [
      { key: CASH_ON_DELIVERY_CODE, value: selectedCheckmo},
      { key: STRIPE_PAYMENT_METHOD_CODE, value: selectedstripe_payments },
      { key: PURCHASE_ORDER_CODE, value: selectedPurchaseorder },
      {key:CHEQUE_PURCHASE_ORDER_CODE, value:selectedChequeIcon}
    ];

    useEffect(()=>{
      gtmDataConstructor("add_payment_info",STRIPE_PAYMENT_METHOD_CODE);
    },[])
    
    // Function to retrieve value based on key
    function getValueByKey(arr:paymentIconMappingType[], key:string) {
      const pair = arr.find(item => item.key === key);
      return pair ? pair.value : undefined;
    }

    function modifyPaymentMethods(arr:availablePaymentMethodType[]) {
      let purchaseOrderExists = false;
      for (const item of arr) {
          if (item.code === PURCHASE_ORDER_CODE) {
              purchaseOrderExists = true;
              item.title = BANK_TRANSFER_LABEL;
          }
      }
      if (purchaseOrderExists) {
          arr.push({
              "code": CHEQUE_PURCHASE_ORDER_CODE,
              "title": CHECQUE_LABEL
          });
      }
      return arr;
  }
  

    useEffect(()=>{
      if(!location?.state?.isSecondPayment){
        setIsAPICallRunning(true);
        doActionGet({
          url: apiList.getAvailablePaymentMethods,
          userType:USER_TYPE.Customer
        })?.then((resp: any) => {
          let availablePaymentMethod = resp?.data ? resp?.data?.reverse() :[];
            setAvailablePaymentMethod(modifyPaymentMethods(availablePaymentMethod));
        }
        ).catch((error: any) => {
          console.log("error msg", error);
          showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
        }).finally(()=>{
          setIsAPICallRunning(false);
        })
      }
    },[])


    const paymentMethodSelector =(code:string)=>{
      gtmDataConstructor("add_payment_info",code);
      setSelectedPaymentMethod(code);
    }

    return (
    <S.Content>
         {isAPICallRunning && <Loading />}
        <div className="customer-checkout">
        { !location?.state?.isSecondPayment &&<> <CheckoutHeader headerText={CHECKOUT_FIRST_STEP_HEADING}/>
            <CheckoutProgressBar id={3} title={progressBar[2].title}/> </>}
           { location.pathname===CHECKOUT_PAYMENT_PATH && <div className="customer-payment-section">
              <div className="first-address-row first-row">{PAYMENT_METHOD}</div>
                
                
                 {availablePaymentMethod && availablePaymentMethod.length>0 &&
                  <div className="payment-method-list-section">
                      {availablePaymentMethod.map((item:availablePaymentMethodType,index:number)=>{
                          return (
                            <React.Fragment key={item?.code}>
                          <div className={selectedPaymentMethod===item.code ? "payment-method-section-selected":"payment-method-section"} onClick={()=>{ paymentMethodSelector(item.code)}}>
                          <div className="code-image"><img  src={getValueByKey(selectedPaymentMethod===item.code?paymentMethodSelectedCodeAndIconMapping:paymentMethodCodeAndIconMapping,item.code)} alt="PaymentIcon" className="payment-icon-image" /></div>
                          <div className="code-title">{item.title}</div>
                          </div>
                          </React.Fragment>
                          )
                      })}
                  </div>
                  }
            </div>}
            <>
                  {selectedPaymentMethod === STRIPE_PAYMENT_METHOD_CODE &&   location.pathname===CHECKOUT_PAYMENT_PATH &&<CustomerCardListing isSecondPayment={false} methodTitle={getPaymentMethodTitle(availablePaymentMethod,STRIPE_PAYMENT_METHOD_CODE)}/>}
                  {selectedPaymentMethod === PURCHASE_ORDER_CODE && <NetBanking isSecondPayment={false}  methodTitle={getPaymentMethodTitle(availablePaymentMethod,PURCHASE_ORDER_CODE)} />}
                  {selectedPaymentMethod === CASH_ON_DELIVERY_CODE && <Cash isSecondPayment={false}  methodTitle={getPaymentMethodTitle(availablePaymentMethod,CASH_ON_DELIVERY_CODE)}/>}
                  {selectedPaymentMethod === CHEQUE_PURCHASE_ORDER_CODE && <Cheque isSecondPayment={false}  methodTitle={getPaymentMethodTitle(availablePaymentMethod,PURCHASE_ORDER_CODE)} />}
                  </>
                  
                  {selectedPaymentMethod === STRIPE_PAYMENT_METHOD_CODE &&  location.pathname===CHECKOUT_PAYMENT_CARD_FORM_PATH && <CustomerPaymentForm isSecondPayment={location?.state?.isSecondPayment}  methodTitle={getPaymentMethodTitle(availablePaymentMethod,STRIPE_PAYMENT_METHOD_CODE)}/>}
             
        </div>
    </S.Content>
  );
};

export default CustomerCheckoutPayment;
