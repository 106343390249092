import React, { useEffect, useMemo, useState } from "react";
import * as S from "./styles/styles";
import { capitalizeFirstLetter, formatDate, mergeProducts } from "./orderHelpers";
import { useLocation, useNavigate } from "react-router-dom";
import { formatPriceAndCurrency } from "../../../../helpers/utlis";
import useFetchOrders from "./hooks/useFetchOrders";
import { Order } from "./types";
import { LIST_PAGE_LABELS, NO_ORDERS_FOUND, statusMap, tabMap } from "./constants";
import { Pagination, Tab, Tabs } from "@mui/material";
import apiList, { ALERT_STATUS, NO_OF_ROWS_IN_PAGE, USER_TYPE } from "../../../../lib/constant";
import { doActionPut } from "../../../../helpers/httpRequest";
import { useNotification } from "../../../../hooks/useNotification";
import ItemList from "./ItemList.tsx";

const MyOrders = () => {
    const location = useLocation();
    const customer_id = location.state?.data ?? '';
    const [page, setPage] = React.useState(1);
    const [statuses,setStatuses] = useState<string[]>([]);
    const { orders, isLoading, refreshOrders ,totalCount} = useFetchOrders(customer_id,"",page,NO_OF_ROWS_IN_PAGE,statuses);
    const navigate = useNavigate()
    const { showAlert } = useNotification();
    const [value, setValue] = useState(0);
    const [returnAll, setReturnAll] = useState({ orderId: '', selectAll: false });
    const [returnItemIds, setReturnItemIds] = useState<string[]>([]);

    useEffect(()=>{
        if (tabMap[value as keyof typeof tabMap]) {
            setPage(1);
            setStatuses(tabMap[value as keyof typeof tabMap]);
        } else {
            setStatuses([]);
        }
    },[value])

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const cancelOrReturnCurrentOrder = async (orderId: string, toCancel: boolean, mergedItems: any[], order?: any, returnItemIds?: string[], returnAll?: ({ orderId: string, selectAll: boolean })) => {

        try {
            const response = await doActionPut({
                url: apiList.cancelOrReturnOrder(orderId, toCancel),
                userType: USER_TYPE.Customer,
                data: {
                    "orderReturnCancelRequest": {
                        ...((returnItemIds?.length) ? { "line_item_ids": returnItemIds } : {}),
                        "reason": "You can configure webhooks manually with the following command",
                        "pos_txn_ref": "123"
                    }
                }
            })
            if (response?.data) {
                showAlert(`Order ${toCancel ? 'cancellation' : 'return'} raised`, ALERT_STATUS.success)
                refreshOrders()
            setReturnAll({ orderId: '', selectAll: false })
                const { base_currency_code, tax_amount, shipping_amount, total_paid } = order
                const items = mergedItems.map((mItem, index) => {
                    const { sku, name, discount_amount, price, qty_ordered, quote_item_id } = mItem
                    return ({
                        item_id: sku,
                        item_name: name,
                        affiliation: "",
                        coupon: "",
                        discount: discount_amount,
                        index,
                        item_brand: "",
                        item_category: "",
                        item_category2: "",
                        item_category3: "",
                        item_category4: "",
                        item_category5: "",
                        item_list_id: quote_item_id,
                        item_list_name: "",
                        item_variant: "",
                        location_id: "",
                        price,
                        quantity: qty_ordered
                    })
                })
                window?.dataLayer?.push({
                    event: toCancel ? "cancel" : "return",
                    currency: base_currency_code,
                    transaction_id: "",
                    value: total_paid,
                    coupon: "",
                    shipping: shipping_amount,
                    tax: tax_amount,
                    items
                });
            }
        }
        catch (err) { showAlert(`Order ${toCancel ? 'cancellation' : 'return'} failed!`, ALERT_STATUS.error) }
    }
    const filteredOrders = useMemo(() => {
        return orders?.filter(order =>
            !value || (value in tabMap && tabMap[value as keyof typeof tabMap].includes(order.status))
        );
    }, [orders, value]);


    const orderList = orders?.map((order: Order) => {
        const { items, entity_id, created_at, status, extension_attributes, increment_id } = order
        const { is_available_for_cancel, is_available_for_return } = extension_attributes ?? {}
        
        const itemCount = items?.filter(item => item.product_type === 'simple').length
        const selectAll = (returnAll?.orderId === String(entity_id)) && (returnAll?.selectAll)
        const mergedItems = mergeProducts(items)
        let  returnableOrder = is_available_for_return && items.some(item => item?.extension_attributes?.is_available_for_return)
        const isPaymentApprovalRequired = order?.extension_attributes?.payment_list?.filter((paymentList: any) => {
            return paymentList?.status === "Approval Pending"
        })?.length > 0 ? true : false;

        return <React.Fragment key={entity_id}>
            <div className='order'>
                <div className='title-line'>
                    <div className="orderId">{`Order #${increment_id}`}</div>
                    <p>{formatDate(created_at)}</p>
                </div>
                <div className='status-line'>
                    <span>{LIST_PAGE_LABELS.orderStatus.en}</span>
                    <p>{(status in statusMap) ? statusMap[status as keyof typeof statusMap] : capitalizeFirstLetter(status)}</p>
                </div>
                <div className="pricing-with-return">
                    <p className="pricing">{`${itemCount} ${itemCount > 1 ? 'items' : 'item'} | ${formatPriceAndCurrency(order?.grand_total)}`}</p>
                    {returnableOrder && <div key={'select-all' + entity_id} className="checkbox-wrapper">
                        <input
                            id={'select-all'}
                            type="checkbox"
                            className="custom-checkbox"
                            name={'select-all'}
                            checked={selectAll}
                            onChange={() => {
                                if (selectAll) { setReturnAll({ orderId: '', selectAll: false }); setReturnItemIds(mergedItems.map(item => item.item_id)) }
                                else { setReturnAll({ orderId: String(entity_id), selectAll: true }); setReturnItemIds(items?.filter((item) => item?.extension_attributes?.is_available_for_return)?.map(item => item.item_id)) }
                            }}
                        />
                        <label htmlFor={'select-all'} className="checkbox-label">
                            {'Select All'}
                        </label>
                    </div>}
                </div>
                <div className="title-text">{LIST_PAGE_LABELS.products.en} </div>
                <div className='item-container'>
                    <ItemList items={mergedItems} orderId={String(entity_id)} returnAll={returnAll} setReturnAll={setReturnAll} returnItemIds={returnItemIds} setReturnItemIds={setReturnItemIds} />
                    <div className="button-container">
                        <button className="order-detail-btn" onClick={() => {
                            if (customer_id) navigate(`/cutomer-order/${String(entity_id)}`, { state: { data: customer_id } })
                            else navigate(`/order/${String(entity_id)}`)
                        }}>View Order details</button>
                        {Boolean(customer_id) ? (isPaymentApprovalRequired
                            ? <button className="cancel-return-btn" onClick={() => { navigate(`/cutomer-order/${String(entity_id)}`, { state: { data: customer_id } }) }}>Payment Verification</button>
                            : null)
                            : <>{is_available_for_cancel && <button className="cancel-return-btn" onClick={() => { cancelOrReturnCurrentOrder(String(entity_id), true, mergedItems, order) }}>Cancel Order</button>}
                                {returnableOrder && <button className={`cancel-return-btn ${returnAll?.orderId !== String(entity_id) ? "disabled" : ""}`}
                                    onClick={() => { cancelOrReturnCurrentOrder(String(entity_id), false, mergedItems, order, returnItemIds, returnAll) }}
                                    disabled={returnItemIds?.length===0}
                                >{'Return Items'}</button>}</>}

                    </div>
                </div>
            </div>
        </React.Fragment >

    })
    return <S.Content >
        <div className="title">My Orders</div>
        <Tabs
            value={value}
            onChange={(_, val) => { setValue(val) }}
            variant="fullWidth"
            centered
            className="tab-container"
        >
            <Tab label="All Orders" className={`tab-label ${value === 0 ? 'tab-label-selected' : ''}`} />
            <Tab label="Delivered" className={`tab-label ${value === 1 ? 'tab-label-selected' : ''}`} />
            <Tab label="Returns" className={`tab-label ${value === 2 ? 'tab-label-selected' : ''}`} />
        </Tabs>
        {(orders?.length && orderList?.length) ? orderList : (!isLoading &&
            <div className='no-item-text'><span>{NO_ORDERS_FOUND}</span></div>)}
        {totalCount>0 &&<S.PaginationContent>
                <div className='paginationContainer'>
                  <Pagination count={Math.ceil(totalCount / NO_OF_ROWS_IN_PAGE)} page={page} onChange={handleChange} />
              </div>
              </S.PaginationContent>}    
        </S.Content >

            


}
export default MyOrders; 