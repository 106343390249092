import React, { useContext, useEffect, useState } from "react";
import * as S from "../../styles/styles";
import apiList, { ACCOUNT_NAME, ACCOUNT_NAME_TITLE, ACCOUNT_NUMBER, ACCOUNT_NUMBER_TITLE, ADD_NEW_CARD_HEADING, ALERT_STATUS, BANK_NAME, BANK_NAME_TITLE, BANK_TRANSFER_PAYMENT_METHOD_LABEL, CARD_NO_LABEL, CVV_LABEL, EXPIRY_DATE_LABEL, IBAN_NUMBER, IBAN_TITLE, NO_AVAILABLE_CARDS, PAY_BUTTON_LABEL, PLACE_ORDER_BUTTON_LABEL, PURCHASE_ORDER_CODE, SAVED_CARD_HEADING, SOMETHING_WENT_WRONG, TRANSACTION_REFERENCE_NUMBER, TRANSACTION_REFERENCE_NUMBER_PLACEHOLDER, USER_TYPE } from "../../../../../lib/constant";
import { CHECKOUT_PAYMENT_CARD_FORM_PATH, ORDER_FAILURE_PAGE, ORDER_SUCCESS_PAGE } from "../../../../../routes/routes-constant";
import { useLocation, useNavigate } from "react-router-dom";
import { doActionGet, doActionPost, doActionPut } from "../../../../../helpers/httpRequest";
import { useNotification } from "../../../../../hooks/useNotification";
import { Loading } from "../../../../atoms/loading/Loading";
import CheckoutButton from "../../../../atoms/checkout-button/CheckoutButton";
import { clearCartLocalStorage, getGrandTotalValue, gtmDataConstructor, raiseAssistanceRequest } from "../../helper";
import { ACTION_TYPE } from "../../../../../Context/Constant";
import { DataContext } from "../../../../../Context/AppContext";
import { paymentMethodProps } from "../../types/types";
import { secondPaymentCompletion_info_gtm } from "../../../../../helpers/GTM";


const NetBanking: React.FC<paymentMethodProps> = ({isSecondPayment,methodTitle}) => {
    const navigate = useNavigate();
    const [isAPICallRunning, setIsAPICallRunning] = useState(false);
    const { showAlert } = useNotification();
    const [transctionRefNo, setTransactionRefNo] = useState<string>("");
    const { dispatch } = useContext(DataContext);
    const location = useLocation();

    const placeOrder = () => {
        setIsAPICallRunning(true);
        if(isSecondPayment){
            //second payment implementation
              if(location?.state && location?.state?.orderId && location?.state?.totalDue){
                doActionPut({
                    url: apiList.setPendingOrder(location?.state?.orderId),
                    data:
                    {
                        "paymentMethod": {
                            "method": PURCHASE_ORDER_CODE,
                            "po_number": transctionRefNo,
                            "extension_attributes" : {
                                "paid_amount": location?.state?.totalDue,
                                "method_title": methodTitle,
                                "payment_type":"Bank Transfer",
                            }
                        }
                        
                    },
                    userType: USER_TYPE.Customer
                })?.then((resp: any) => {
                    if(resp){
                        raiseAssistanceRequest();
                        navigate(ORDER_SUCCESS_PAGE, { state: { orderId: location?.state?.orderId,isSecondPayment:isSecondPayment, approvalRequired:true,incrementId:location?.state?.incrementId   } })
                        secondPaymentCompletion_info_gtm(location?.state?.totalDue , location?.state?.orderId,PURCHASE_ORDER_CODE);
                    }
                    else{
                        showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
                    }
                }
                ).catch((error: any) => {
                    console.log("error msg", error);
                    //navigate(ORDER_FAILURE_PAGE);
                    showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
                }).finally(() => {
                    setIsAPICallRunning(false);
                })
             }
        }
        else{
        doActionPut({
            url: apiList.placeOfflineOrders,
            data:
            {
                "paymentMethod": {
                    "method": PURCHASE_ORDER_CODE,
                    "po_number": transctionRefNo,
                    "extension_attributes" : {
                        "paid_amount": getGrandTotalValue()
                    },
                    "additional_data": {
                        "payment_type": "Bank Transfer",
                    }
                },

            },
            userType: USER_TYPE.Customer
        })?.then((resp: any) => {
            if(resp){
                raiseAssistanceRequest();
                gtmDataConstructor("purchase",PURCHASE_ORDER_CODE,resp?.data);
                clearCartLocalStorage();
                dispatch({ type: ACTION_TYPE.set_item_count, payload: { count: 0 } })
                navigate(ORDER_SUCCESS_PAGE, { state: { orderId: resp?.data?.order_id ,incrementId:resp?.data?.increment_id, approvalRequired:true} })
            }
            else{
                showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
            }
        }
        ).catch((error: any) => {
            console.log("error msg", error);
            //navigate(ORDER_FAILURE_PAGE);
            showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
        }).finally(() => {
            setIsAPICallRunning(false);
        })
    }

    }

    return (
        <S.Content>
            {isAPICallRunning && <Loading />}
            <div className="customer-delivery">
                <div className="bank-info">
                <div className="info-data-section">
                    <div className="info-title">
                    Please find below bank details for bank transfer
                    </div>
                    </div>
                    <div className="info-section">
                    <div className="info-title">
                    {BANK_NAME_TITLE}
                    </div>
                    <div className="info-data">
                    <div className="info-heading">{BANK_NAME}</div>
                    <div className="copy-section" onClick={()=>{navigator.clipboard.writeText(BANK_NAME)}}>Copy</div>
                    </div>
                    <div className="info-title">
                    {ACCOUNT_NAME_TITLE}
                    </div>
                    <div className="info-data">
                    <div className="info-heading">{ACCOUNT_NAME}</div>
                    <div className="copy-section" onClick={()=>{navigator.clipboard.writeText(ACCOUNT_NAME)}}>Copy</div>
                    </div>
                    <div className="info-title">
                    {ACCOUNT_NUMBER_TITLE}
                    </div>
                    <div className="info-data">
                    <div className="info-heading">{ACCOUNT_NUMBER}</div>
                    <div className="copy-section" onClick={()=>{navigator.clipboard.writeText(ACCOUNT_NUMBER)}}>Copy</div>
                    </div>
                    <div className="info-title">
                    {IBAN_TITLE}
                    </div>
                    <div className="info-data">
                    <div className="info-heading">{IBAN_NUMBER}</div>
                    <div className="copy-section" onClick={()=>{navigator.clipboard.writeText(IBAN_NUMBER)}}>Copy</div>
                    </div>

                    </div>
                </div>
                <div className="checkout-card-data">
                <div className='card-holder-heading transaction-box'>{TRANSACTION_REFERENCE_NUMBER}</div>
                <div className="second-content-row">
                    <input className="input-box transaction-input-box" type="text" placeholder={TRANSACTION_REFERENCE_NUMBER_PLACEHOLDER} value={transctionRefNo} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setTransactionRefNo(e.target.value) }}></input>
                </div>
            </div>
            </div>
            <div className="checkout-continue-button place-net-order">
                <CheckoutButton isDisabled={false} label={PLACE_ORDER_BUTTON_LABEL} checkoutHandler={placeOrder} />
            </div>
        </S.Content>
    );
};

export default NetBanking;
