import React, { useState } from "react";
import { MOLECULES_CONSTANT } from "../Constant";
import { formatPriceAndCurrency } from "../../../helpers/utlis";

interface cardProps {
  cardkey: number;
  handleQuantity: ({ sku, add,item }: { sku: string, add: boolean ,item:any}) => void;
  item: {
    name: string;
    sku: string;
    description: string;
    price: number;
    imageUrl: string;
    status: string;
    rating: number;
    quantity: number;
    type_id?:string;
    extension_attributes: {
      saleable_quantity: number;
      stock_item: {
        is_in_stock: boolean;
        max_sale_qty:number;
      }
    },
    media_gallery_entries: [{
      file: string
    }]
  };
}

const ProductCard: React.FC<cardProps> = ({ item, handleQuantity }) => {
  const [imgLoaded, setimgLoaded] = useState(true);
  const handleImgLoad = () => {
    setimgLoaded(false);
  };
  const allowedCount = item?.type_id==="bundle"?(item.extension_attributes?.saleable_quantity?item.extension_attributes?.saleable_quantity:item.extension_attributes?.stock_item?.max_sale_qty):item.extension_attributes?.saleable_quantity ?? 0
  const quantity = item.quantity ?? 0

  return (
    <div className="item_card">
      <section className="product_section">
        <div className="image_section">
          {imgLoaded && <div className="image_loader"></div>}
          <img
            className="product_img"
            onLoad={handleImgLoad}
            src={`${(process.env.REACT_APP_PDP_IMAGES_BASE_URL ?? '') + (item?.media_gallery_entries[0]?.file ?? "no_selection")}`}
            alt="img"
          />
          {(allowedCount < 1) && <div className="fade_layer">{MOLECULES_CONSTANT.out_of_stock.en}</div>}
        </div>
        <div className="details_section">
          <h6 className="details_name">{item.name}</h6>
          <p className="details_sku">{item.sku}</p>
          <h6 className="details_price">
            <strong>{`${formatPriceAndCurrency(item.price)}`}</strong>
          </h6>

        </div>
        <section className="button_section">
          <button
            className="remove_quantity_button"
            onClick={() =>
              handleQuantity({ sku: item.sku, add: false,item})
            }
            disabled={(quantity < 1)}
          >
            -
          </button>
          <div className="value_div"> {quantity}</div>
          <button
            className="add_quantity_button"
            onClick={() => handleQuantity({ sku: item.sku, add: true,item })}
            disabled={(allowedCount < 1) || (quantity >= allowedCount)}
          >
            +
          </button>
        </section>
      </section>

    </div>
  );
};

export default React.memo(ProductCard);