import styled, { css } from "styled-components";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
      background: #f8f7f2;
      .order {
        background: white;
        background: white;
        margin: 20px 0;
        display: flex;
        flex-direction: column;
        padding: 10px 20px;

        .title-line {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          line-height: 0px;
          .orderId{
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #1c1c1c;
          }
          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: #1c1c1c;
          }
          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #6f6855;
          }
        }

        .status-line {
          display: flex;
          width: 100%;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          justify-content: flex-start;
          line-height: 0px;
          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #1c1c1c;
          }
          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #b6b2a6;
            margin-left: 10px;
          }
        }
        .pricing-with-return {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .pricing {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 130%;
            color: #6f6855;
          }
        }

        .title-text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 110%;
          color: #1c1c1c;
        }
        .item-container {
          .button-container {
            display: flex;
            margin: 10px 0;
            justify-content: flex-start;
            line-height: 15px;
            .cancel-return-btn {
              box-sizing: border-box;
              // padding: 8px 16px;
              width: 124px;
              height: 31px;
              border: 1px solid #6f6855;
              background: white;
              filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.05));
              border-radius: 10px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 10px;
              text-align: center;
              color: #6f6855;
              margin-left: 2px;
            }
              .cancel-return-btn:disabled{
                  opacity: 0.5;
    pointer-events: none;
              }
            .order-detail-btn {
              border-radius: 10px;
              width: 124px;
              height: 31px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 10px;
              text-align: center;
              background: #6f6855;
              border: none;
              color: white;
              margin-left: 2px;
            }
          }
          & .item-box {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 5px 0;

            img {
              border-radius: 8px;
              width: 80px;
              height: 84px;
              object-fit: cover;
            }
            .item-detail {
              display: flex;
              flex-direction: column;
              padding: 5px 9px 5px 20px;
              line-height: 14px;
              span {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #6f6855;
              }
              p {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #b6b2a6;
                line-height: 0px;
              }
              .other {
                display: flex;
                flex-direction: column;
                .info {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 0px;
                  display: flex;
                  align-items: center;
                  span {
                    color: #6f6855;
                  }
                  p {
                    color: #b6b2a6;
                    margin-left: 2px;
                  }
                  .sku-label{
                   color: #b6b2a6;
                    margin-left: 2px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height:0px;
                    display: block;
                    margin-block-start: 1em;
                    margin-block-end: 1em;
                  }
                }
              }
            }
          }
        }
      }

                .no-item-text {
        margin: 20px;
        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 110%;
          color: #6f6855;
        }
      }
      .continue-shopping {
        width: 200px !important;
      }
      .title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 110%;
        color: #6f6855;
        padding: 10px 20px;
      }
      .tab-container {
        padding-bottom: 20px;
        & .MuiTabs-indicator {
          position: absolute;
          height: 4px;
          bottom: 4px;
          background: rgb(111, 104, 85);
          border-radius: 0px 0px 6px 6px;
          transform: rotate(180deg) translateX(-55%);
          width: 15% !important;
        }
      }
      .tab-label {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        letter-spacing: 0.03em;
        text-transform: none;
        &.tab-label-selected {
          color: #1c1c1c;
          font-weight: 500;
        }
      }
      .checkbox-container {
        display: flex;
        flex-direction: column;
        background: #f8f7f2;
        padding: 10px 0;
      }

      .checkbox-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }

      .custom-checkbox {
        width: 16px;
        height: 16px;
        border: 1px solid #6f6855;
        border-radius: 3px;
        appearance: none;
        outline: none;
        cursor: pointer;
        position: relative;
        margin-right: 8px;
        background-color: #fff;
      }

      .custom-checkbox:checked {
        background-color: #6f6855;
      }

      .custom-checkbox:checked::after {
        content: "";
        display: block;
        width: 4px;
        height: 8px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        position: absolute;
        top: 38%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      .checkbox-label {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        color: #6f6855;
      }
      .item-box-check {
        display: flex;
        width: 100%;
        justify-content: space-between;
        border-bottom: 0.4px solid #b6b2a6;
      }
      .item-box-check-box{
      display: flex;
        width: 100%;
        justify-content: space-between;
      }
        .acordian-div{
         border-top: 0.4px solid #b6b2a6;
         border-bottom: 0.4px solid #b6b2a6;
         margin-top: 10px;
        margin-bottom: 10px;
        }
      .disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    `;
  }}
`;

export const PaginationContent = styled.div<any>`
${(p:any) => {
    return css`
      .paginationContainer{
        justify-content: center;
        display: flex;
        margin-top: 20px;
        margin-bottom:40px;
      }
     `
}}
`