import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as S from "./styles/styles";
import { doActionGet } from "../../../helpers/httpRequest";
import { productURL, productChildrenURL, productItemWithCustomerTokenURL, productItemWithEmployeeTokenURL, PDP_CONSTANTS } from "./common/constants";
import { AttributeValue, Variant } from "./variantAccordion/types";
import ProductPage from "./ProductPage";
import { getAttributes } from "./common/helpers";
import { getLocalStorage } from "../../../helpers/localStorageUtil";
import { USER_TYPE } from "../../../lib/constant";
import CustomVideoPlayer from "../../molecules/customVideoPlayer";
import { CarouselMedia, VariantCombinationArray } from "./common/types";
import { useAppContext } from "../../../Context/AppContext";
import { ACTION_TYPE } from "../../../Context/Constant";

const StoryTellingVideo = () => {
    const { sku } = useParams();

    let location = useLocation();

  // Helper function to extract query parameters
    const getQueryParams = (queryString:string) => {
        return new URLSearchParams(queryString);
    };

  // Get the query parameters from the location
    const queryParams = getQueryParams(location.search);
    const childSku = queryParams.get("child-sku"); 

    const navigate = useNavigate()
    const { dispatch } = useAppContext()
 
    const { itemId, quoteId, fromCart, user, specialOrderQuoteId } = location?.state ?? {};
    const [basicInfo, setBasicInfo] = useState<any>();
    const [variants, setVariants] = useState<Variant[]>([]);
    const [selectedMediaId, setSelectedMediaId] = useState<string>('');
    const [attributes, setAttributes] = useState<AttributeValue[][]>([]);
    const [variantCombinations, setVariantCombinations] = useState<VariantCombinationArray>()
    const [showProduct, setShowProduct] = useState(() => { return (Boolean(fromCart) || (sessionStorage.getItem('videoShown') === (sku ?? ''))) });
    const [media, setMedia] = useState<CarouselMedia[]>([]);
    const [filters, setFilters] = useState<{ [key: string]: string }>({})
    const [preSelectedVariant, setPreSelectedVariant] = useState<{ option_id: string; option_value: number }[]>()

    const storyTellingVideoUrl = getAttributes(
        ["storytelling_video"],
        basicInfo?.custom_attributes
    )?.storytelling_video;

    useEffect(() => {
        const hasTokens = getLocalStorage('access-token') || getLocalStorage('user-token');
        const shouldRedirect = basicInfo && (!storyTellingVideoUrl || (storyTellingVideoUrl && sessionStorage.getItem('videoShown')));

        if (!hasTokens) {
            sessionStorage.setItem('pdp_redirect_sku', sku+`?child-sku=${childSku}` ?? '')
            if (shouldRedirect) navigate("/login")
        }

        if (basicInfo && !storyTellingVideoUrl) { sessionStorage.removeItem('videoShown'); setShowProduct(true) }

    }, [storyTellingVideoUrl, basicInfo, sku]);


    useEffect(() => {
        const fetchProductItem = async () => {
            try {
                if (!getLocalStorage('user-token') && !getLocalStorage("access-token")) navigate('/login')

                let url: string, userType: string
                if (getLocalStorage('user-token')) { url = productItemWithCustomerTokenURL(itemId); userType = USER_TYPE.Customer }
                else if (quoteId) { url = productItemWithEmployeeTokenURL(quoteId, itemId); userType = USER_TYPE.Employee }
                else throw Error('quote ID is missing')

                const response = await doActionGet({ url, userType });
                const variant = response?.data?.product_option?.extension_attributes?.configurable_item_options ?? []
              
                setPreSelectedVariant(variant)
            } catch (error) {
                console.error("Error fetching product item details:", error);
            }
        };

        if (itemId) fetchProductItem();
        else setPreSelectedVariant([])

    }, [itemId, navigate, quoteId]);

    useEffect(() => {
     
        const fetchProduct = async () => {
            try {
                const response = await doActionGet({
                    url: productURL(sku),
                    userType: USER_TYPE.Other
                });
              
                dispatch({ type: ACTION_TYPE.set_current_PDP, payload: response?.data })
                setBasicInfo(response?.data);
      
                setMedia(response?.data?.media_gallery_entries?.map((entry: { id: any; media_type: string; file: string; extension_attributes: { video_content: { video_url: any; }; }; }) =>
                ({
                    id: String(entry.id),
                    type: entry.media_type,
                    url: (entry.media_type === 'image') ? ((process.env.REACT_APP_PDP_IMAGES_BASE_URL ?? '') + entry.file) : entry?.extension_attributes?.video_content?.video_url
                }))
                )
           
                setSelectedMediaId(String(response?.data?.media_gallery_entries?.[0]?.id));
                let filters: { [key: string]: string } = {}
                let variantCombinations: VariantCombinationArray = []

                let variantsData: Variant[] = response?.data?.extension_attributes?.additional_configurable_attributes?.filter((v: { values: string | any[]; }) => (v?.values?.length > 0)).map(
                    (product: {
                        label: any;
                        values: any;
                        extension_attributes: { attribute_code: string };
                        attribute_id: string;
                    }) => {
                        const { label, values, extension_attributes, attribute_id } = product;
                        if (preSelectedVariant?.length) {
                            const matchingVariant = preSelectedVariant.find((variant) => variant.option_id === attribute_id)
                            if (matchingVariant) {
                                filters[extension_attributes?.attribute_code] = String(matchingVariant.option_value)
                            }
                            else filters[extension_attributes?.attribute_code] = "*"
                        }
                        const optionsData = values.map(
                            (val: {
                                value_index: any;
                                extension_attributes: { image: string; label: string }
                            }) => ({
                                value_index: val.value_index,
                                label: val?.extension_attributes?.label,
                                ...(val?.extension_attributes?.image ? { image: val?.extension_attributes?.image } : {})
                            })
                        )
                        const seen = new Set();
                        const options = optionsData.filter((obj: { value_index: number; label: string; }) => {
                            const key = `${obj.value_index}-${obj.label}`;
                            return seen.has(key) ? false : seen.add(key);
                        });
                        return {
                            label,
                            attribute_id,
                            code: extension_attributes?.attribute_code,
                            options
                        };
                    }
                );
                setFilters(filters);
                if (response?.data?.type_id === "bundle") {
                    variantsData = response?.data?.extension_attributes?.additional_configurable_attributes.filter((attr:any) => attr.value !== null).map((attr:any) => (
                        {
                        attribute_id: attr.attribute_id,
                        code: attr.attibute_code,
                        label: attr.label,
                        options: attr.value_label 
                            ? [{ value_index: parseInt(attr.value), label: attr.value_label }]
                            : []
                    }));
                    let attributeSetData:any=  [];
                    let childFilters: { [key: string]: string } = {};
                    response?.data?.extension_attributes?.additional_configurable_attributes.filter((attr:any) => attr.value !== null).map((attr:any) => {
                        const options = attr.value_label
                            ? [{ value_index: parseInt(attr.value as string), label: attr.value_label }]
                            : [];
                        
                        attributeSetData.push({attribute_code: attr.attibute_code, value: attr.value});  
                        childFilters[attr.attibute_code]  = attr.value;
                        return {
                            attribute_id: attr.attribute_id,
                            code: attr.attibute_code,
                            label: attr.label,
                            options
                        };
                    });
                    
                    // Generate `valueSet` for variant combinations
                    const valueSet = response?.data?.extension_attributes?.additional_configurable_attributes
                        .map((attr:any) => attr.value ? `${attr.value}-` : '')
                        .filter(Boolean) // Remove any empty values
                        .join('');
                    
                    // Populate `variantCombinations` using `valueSet`
                    variantCombinations.push({
                        id: "145",
                        image_url: "",
                        price: 0,
                        qty:0,
                        valueSet
                    });
                    setAttributes([attributeSetData]);
             
                    setFilters(childFilters);
                    
                }
                let enableAnyOptionFor = new Set()
                let preDefinedFilters: { [key: string]: string } = {}
                if (response?.data?.type_id === "configurable") {
                    const response = await doActionGet({
                        url: productChildrenURL(sku),
                        userType: USER_TYPE.Other
                    });
                    if((response?.data?.length>0)){
                        let childFilters: { [key: string]: string } = {};
                        var selectedSkuData ;
                        if(childSku){
                             selectedSkuData = response?.data?.find((item:any)=>{
                                return item?.sku?.toUpperCase() === childSku?.toUpperCase(); 
                            })
                        }
                        else{
                            selectedSkuData = response?.data[0];
                        }
       
                        const resultArray = selectedSkuData?.custom_attributes?.reduce((acc:any, attribute:any) => {
                            // Find matching metadata based on code
                            const matchingMetadata = variantsData?.find(meta => meta.code === attribute.attribute_code);
                          
                            if (matchingMetadata) {
                              // If a match is found, push an object with attribute_id and value to the result array
                              acc.push({
                                option_id: matchingMetadata.attribute_id,
                                option_value: attribute.value
                              });
                              childFilters[matchingMetadata?.code] = String(attribute.value)
                            }
                          
                            return acc;
                          }, []);
                          setFilters(childFilters);
                    }
                
                    let attributes: AttributeValue[][] = [];
                    const variantCodes = variantsData?.map((variant) => variant.code);
                    variantCombinations = response?.data?.map(
                        (variant: {
                            price: number;
                            custom_attributes: { attribute_code: string, value: string }[];
                            extension_attributes: { [key: string]: { [key: string]: number | boolean } }
                            id: string;
                            sku: string;
                        }) => {
                            let { price, custom_attributes, extension_attributes, sku: variant_sku } = variant;
                            let qty = extension_attributes?.saleable_quantity ?? extension_attributes?.stock_item?.qty
                            const valueSet = variantCodes?.map(vCode => {

                                const value = (custom_attributes?.filter((attrib) => attrib.attribute_code === vCode)?.[0]?.value)
                                if (value) return value + '-'
                                else { enableAnyOptionFor.add(vCode); return '*-' }
                            }).join('')

                            let attributeSet = custom_attributes?.filter((attr: { attribute_code: string }) =>
                                variantsData?.map((v: { code: any }) => v.code).includes(attr.attribute_code)
                            )
                            variantCodes?.forEach(vCode => {
                                if (attributeSet?.every(attr => attr.attribute_code !== vCode)) attributeSet.push({ attribute_code: vCode, value: '*' })
                            })
                            attributes.push(
                                attributeSet?.sort((a, b) => (variantCodes.indexOf(a.attribute_code) - variantCodes.indexOf(b.attribute_code)))
                            );
                            return {
                                price,
                                qty,
                                id: custom_attributes?.filter(attr => attr.attribute_code === 'configurable_variant')?.[0]?.value,
                                image_url: custom_attributes?.filter(
                                    (attr: { attribute_code: string }) =>
                                        attr.attribute_code === "image"
                                )?.[0]?.value,
                                valueSet,
                                variant_sku
                            };
                        }
                    );
                    setAttributes(attributes);
                    if (response?.data.length === 1) {
                        variantsData?.forEach(variant => {
                            preDefinedFilters[variant.code] = (response?.data[0]?.custom_attributes ?? []).find((attr: { attribute_code: string; }) => attr?.attribute_code === variant?.code)?.value
                        })
                    }
                }
                variantsData = variantsData?.map(variantData => enableAnyOptionFor.has(variantData.code) ?
                    { ...variantData, options: variantData.options.concat([{ label: 'Any', value_index: '*', image: '' }]) } :
                    variantData)
                setVariantCombinations(variantCombinations)
                setVariants(variantsData);
            
                if (Object.keys(preDefinedFilters).length) setFilters(preDefinedFilters)

            } catch (error) {
                console.error("Error fetching product details:", error);
                setVariantCombinations([])
            }
        };
        if (sku && preSelectedVariant) fetchProduct();
    }, [sku, preSelectedVariant, dispatch]);

    const handleSkipOrFinish = () => {
        sessionStorage.setItem('videoShown', sku ?? '')
        if (getLocalStorage('user-token')) setShowProduct(true)
        else navigate("/login")
    }

    const showStoryTellingVideo = storyTellingVideoUrl && !getLocalStorage('access-token') && !showProduct

    if (!variantCombinations) return null
    return (
        showStoryTellingVideo
            ? <CustomVideoPlayer videoUrl={storyTellingVideoUrl} onSkipOrFinish={handleSkipOrFinish} />
            : <S.Content>
                {basicInfo ?
                    <ProductPage
                        sku={sku ?? ""}
                        basicInfo={basicInfo}
                        variants={variants}
                        selectedMediaId={selectedMediaId}
                        attributes={attributes}
                        setSelectedMediaId={setSelectedMediaId}
                        variantCombinations={variantCombinations}
                        media={media}
                        setMedia={setMedia}
                        setShowProduct={setShowProduct}
                        filters={filters}
                        setFilters={setFilters}
                        user={user}
                        specialOrderQuoteId={specialOrderQuoteId} />
                    : !variantCombinations?.length ? <div className="no-item-text"><span > {PDP_CONSTANTS.product_not_found}</span> </div> : null
                }
            </S.Content>)
}

export default StoryTellingVideo;
